<template>
  <div class="main-container">
    
    <p>Pedidos</p>

    <button @click="authIFood()">Auth Food</button>


<div class="row">
  <div class="col-xs-12 col-md-8">.col-xs-12 .col-md-8</div>
  <div class="col-xs-6 col-md-4">.col-xs-6 .col-md-4</div>
</div>

<!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop -->
<div class="row">
  <div class="col-xs-6 col-md-4">.col-xs-6 .col-md-4</div>
  <div class="col-xs-6 col-md-4">.col-xs-6 .col-md-4</div>
  <div class="col-xs-6 col-md-4">.col-xs-6 .col-md-4</div>
</div>

<!-- Columns are always 50% wide, on mobile and desktop -->
<div class="row">
  <div class="col-xs-6">.col-xs-6</div>
  <div class="col-xs-6">.col-xs-6</div>
</div>    

<div class="row">
  <div class="col-md-1">.col-md-1</div>
  <div class="col-md-1">.col-md-1</div>
  <div class="col-md-1">.col-md-1</div>
  <div class="col-md-1">.col-md-1</div>
  <div class="col-md-1">.col-md-1</div>
  <div class="col-md-1">.col-md-1</div>
  <div class="col-md-1">.col-md-1</div>
  <div class="col-md-1">.col-md-1</div>
  <div class="col-md-1">.col-md-1</div>
  <div class="col-md-1">.col-md-1</div>
  <div class="col-md-1">.col-md-1</div>
  <div class="col-md-1">.col-md-1</div>
</div>
<div class="row">
  <div class="col-md-8">.col-md-8</div>
  <div class="col-md-4">.col-md-4</div>
</div>
<div class="row">
  <div class="col-md-4">.col-md-4</div>
  <div class="col-md-4">.col-md-4</div>
  <div class="col-md-4">.col-md-4</div>
</div>
<div class="row">
  <div class="col-md-6">.col-md-6</div>
  <div class="col-md-6">.col-md-6</div>
</div>



  </div>
</template>

<script>
  export default{
    
    name:'pedido',

    methods:{
      async authIFood(){


            var axios = require('axios');
            var qs = require('qs');
            var data = qs.stringify({
              'grantType': 'client_credentials',
              'clientId': '07fc95aa-3418-4ff7-99a0-de30de6ca06d',
              'clientSecret': 'rwf5f3by6659j53uvn5mcccu41lgro26kneux2vk1oxd313wg8012r5tam3vuurl6oxr6t52tzd8sx5i9ndsriivyrzevdr3h92' 
            });
            var config = {
              method: 'post',
              url: 'https://merchant-api.ifood.com.br/authentication/v1.0/oauth/token',
              headers: { 
                
             

                'Content-Type': 'application/x-www-form-urlencoded'
              },
              data : data
            };

            axios(config)
            .then(function (response) {
              console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log(error);
            });



        }



    }



  }

  //"Access-Control-Allow-Origin": "*",
  //        "Access-Control-Allow-Headers": "Authorization", 
  //        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE" ,


</script>

<style scoped>
  .main-container{
    margin: 50px;
    min-height: 250px;
  }
</style>